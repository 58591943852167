import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo/logo.png";
import LazyLoadImageWrapper from "./LazyLoadImageWrapper";

const MobileNav = () => {
  const pages = ["Home", "portfolio", "Services", "About", "Contact"];

  return (
    <>
      {/* BG BLACK */}
      <div className="black--bg"></div>
      <nav className="mobile-nav">
        <div className="brand-logo">
          <LazyLoadImageWrapper src={logo} alt={"Sofa"} />
        </div>
        <div className="nav-items">
          {pages.map((item, index) => (
            <NavLink to={item.toLowerCase()} className="nav-item" key={index}>
              {item}
            </NavLink>
          ))}
        </div>
      </nav>
    </>
  );
};

export default MobileNav;
