import React, { useContext, useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import Breadcrumb from "../components/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import Banner from "../components/Banner";
import { AppContext } from "../context/AppContext";
import { getAllProducts } from "../api/functions/products";
import { getAllCategories } from "../api/functions/categories";
import Downloads from "../components/downloads";
import { useTranslation } from "react-i18next";
import showToast from "../components/showToast";

const ProductPageWithSide = ({ media }) => {
  const { t, i18n } = useTranslation();
  const { category } = useParams();

  const {
    products: initialProducts,
    setProducts: setProductsContext,
    categories,
    setCategories: setCategoriesContext,
  } = useContext(AppContext);

  const [products, setProducts] = useState(initialProducts);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categoryCounts, setCategoryCounts] = useState({});

  useEffect(() => {
    const filtered = products.filter((product) =>
      getProductTitleByLanguage(product)
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  useEffect(() => {
    const counts = {};
    products.forEach((product) => {
      const categoryId = product.categoryId?.id;
      counts[categoryId] = (counts[categoryId] || 0) + 1;
    });
    setCategoryCounts(counts);
  }, [products]);

  useEffect(() => {
    const updateFilteredProducts = () => {
      if (category) {
        const filteredByCategory = products.filter(
          (product) => product.categoryId?.name?.en === category
        );
        setFilteredProducts(filteredByCategory);
      } else {
        setFilteredProducts(products);
      }
    };

    updateFilteredProducts();
  }, [category, products]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const getProductTitleByLanguage = (product) => {
    const languageCode = i18n.language || "en";
    switch (languageCode) {
      case "en":
        return product.title.en;
      case "ar":
        return product.title.ar || product.title.en;
      case "fr":
        return product.title.fr || product.title.en;
      default:
        return product.title.en;
    }
  };

  const fetchCategoriesData = async () => {
    try {
      const res = await getAllCategories();
      if (res.status === 200) {
        setCategoriesContext(res.data.categories);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // const fetchProductsData = async () => {
  //   try {
  //     const res = await getAllProducts();
  //     if (res.status === 200) {
  //       setProducts(res.data.products);
  //       setProductsContext(res.data.products);
  //     }
  //   } catch (error) {
  //     showToast("Error fetching products:", "error");
  //   }
  // };

  // useEffect(() => {
  //   fetchCategoriesData();
  //   fetchProductsData();
  // }, []);

  return (
    <>
      <Banner page="products" />
      <Breadcrumb page="products" />
      <div id="products" className="default-padding">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 col-md-4 col-sm-12 sidebar">
              <form className="row mb-5" action="#" method="get" role="search">
                <input
                  className="col-9"
                  type="text"
                  name="search"
                  placeholder={t(`products.search`)}
                  onChange={handleSearch}
                />
                <button type="button" className="icon col-3">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </form>

              <ul className="mb-5 p-0">
                {categories?.map((cat) => (
                  <Link
                    key={cat?._id}
                    to={`/products/category/${cat?.name.en}`}
                    className="link d-flex align-items-center gap-3 mb-3"
                  >
                    <button>
                      {i18n.language === "en"
                        ? cat?.name.en
                        : i18n.language === "fr"
                        ? cat?.name.fr
                        : cat?.name.ar}
                      <span
                        className={`item_count ${
                          i18n.language === "ar" ? "me-2" : "ms-2"
                        }`}
                      >
                        {categoryCounts[cat?.id] || 0}
                      </span>
                    </button>
                  </Link>
                ))}
              </ul>

              <label className="mb-3">Contact us</label>
              <div className="buttons">
                <div className="media-icons">
                  {media &&
                    media.map((item, index) => (
                      <Link to={item.link} key={index}>
                        <div className="icon">{item.icon}</div>
                      </Link>
                    ))}
                </div>
              </div>
            </aside>
            <div className="col-lg-9 col-md-8 col-sm-12">
              <div className="row">
                {filteredProducts?.length > 0 ? (
                  filteredProducts?.map((product) => (
                    <ProductCard key={product?.id} product={product} />
                  ))
                ) : (
                  <div className="pl-3">
                    <Downloads />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPageWithSide;
