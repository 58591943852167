import React, { useEffect, useState } from "react";
import location from "../assets/images/header/location.svg";
import mail from "../assets/images/header/mail.svg";
import LazyLoadImageWrapper from "./LazyLoadImageWrapper";
import { useTranslation } from "react-i18next";

const Header = ({ globalInfo }) => {
  const { i18n } = useTranslation();
  const [address, setAddress] = useState({});
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (globalInfo) {
      setAddress(globalInfo.address);
      setEmail(globalInfo.mail);
    }
  }, [globalInfo]);

  return (
    <header id="header">
      <div className="container">
        {/* Left Header */}
        {address?.en && (
          <div className="display-flex-center left-header">
            <figure className="icon m-0">
              <LazyLoadImageWrapper src={location} alt={"Sofa"} />
            </figure>
            <p className="m-0 text-capitalize">
              {i18n.language === "en"
                ? address?.en
                : i18n.language === "ar"
                ? address?.ar
                : address?.fr}
            </p>
          </div>
        )}

        {/* Right Header */}
        {email && (
          <div className="right-header">
            <div className="display-flex-center">
              <figure className="icon m-0">
                <LazyLoadImageWrapper src={mail} alt={"Sofa"} />
              </figure>
              <p className="m-0">{email}</p>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
