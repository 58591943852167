import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const ProductCard = ({ product }) => {
  const { t, i18n } = useTranslation();

  const handleOrderClick = () => {
    const orderText = `Bonjour, je souhaite commander le produit "${product?.title?.en}"`;

    const whatsappNumber = "+212650955743";

    const imageUrl = product?.src;

    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      `${orderText}%0A${imageUrl}`
    )}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <div
      className={`col-lg-5 col-md-6 col-sm-12 mb-3 tab-content ${product?.category}`}
    >
      <div className="card">
        <div className="pr-hero">
          <img
            className="card-img"
            // src={bufferToDataURL(product?.image?.data)}
            src={IMAGE_URL + "/image/" + product.image}
            alt={product?.title.en}
          />
          {product?.discount === 0 ? null : (
            <div className="product-badges">
              <span className="badge onsale">{product?.discount}%</span>
            </div>
          )}
        </div>
        <div className="card-body">
          <h5 className="pr-title">
            {i18n.language === "en"
              ? product?.title.en
              : i18n.language === "fr"
              ? product?.title.fr
              : product?.title.ar}
          </h5>
          <p className="pr-price">
            {product.price > 0 ? (
              product.price + "$"
            ) : (
              <div className="mb-3"></div>
            )}
          </p>
          <button className="prod-button" onClick={handleOrderClick}>
            <FontAwesomeIcon icon={faWhatsapp} />
            {t(`products.orderProduct`)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
