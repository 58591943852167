import React, { Suspense, useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./scss/core.scss";
import "./scss/rtl-support.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Header from "./components/Header";
import MobileNav from "./components/MobileNav";
import MobileTabs from "./components/MobileTabs";
import WhatsappICON from "./components/WhatsappICON";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import ContactPage from "./pages/ContactPage";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RTL_LANGUAGES } from "./i18n";
import ProductsPage from "./pages/ProductsPage";

import PortfolioPage from "./pages/PortfolioPage";
import { AppContext } from "./context/AppContext";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const App = () => {
  const { websiteInfo, contacts } = useContext(AppContext);
  const { i18n } = useTranslation();
  document.dir = i18n.dir();
  const [isLoading, setIsLoading] = useState(null);
  const [isActiveSearchBar, setIsActiveSearchBar] = useState(false);

  const [globalInfo, setGlobalInfo] = useState({});

  useEffect(() => {
    setGlobalInfo({
      description: websiteInfo[0]?.description,
      address: websiteInfo[0]?.address,
      phone: websiteInfo[0]?.phone,
      mail: websiteInfo[0]?.mail,
      logoImage: websiteInfo[0]?.logoImage,
      miniLogoImage: websiteInfo[0]?.miniLogoImage,
      faviconImage: websiteInfo[0]?.faviconImage,
      mapUrl: websiteInfo[0]?.mapUrl,
    });
  }, [websiteInfo]);

  useEffect(() => {
    if (RTL_LANGUAGES.includes(i18n.language)) {
      const bootstrapRtlLink = document.createElement("link");
      bootstrapRtlLink.rel = "stylesheet";
      bootstrapRtlLink.href =
        "https://cdn.rtlcss.com/bootstrap/v5.0.3/css/bootstrap.min.css";
      document.head.appendChild(bootstrapRtlLink);
      document.documentElement.lang = "ar";
      document.body.dir = "rtl";
      document.querySelectorAll(".fj-gallery").forEach((gallery) => {
        gallery.setAttribute("dir", "ltr");
      });
    } else {
      const bootstrapRtlLink = document.querySelector(
        'link[href="https://cdn.rtlcss.com/bootstrap/v5.0.3/css/bootstrap.min.css"]'
      );
      if (bootstrapRtlLink) {
        bootstrapRtlLink.remove();
      }
      document.body.dir = "ltr";
    }
  }, [i18n.language]);

  const getFavicon = async () => {
    const faviconImage = websiteInfo[0]?.faviconImage;
    const faviconLink = document.getElementById("favicon");
    faviconLink.href = `${IMAGE_URL}/image/${faviconImage}`;
  };

  useEffect(() => {
    getFavicon();
  }, [websiteInfo]);

  const [mediaList, setMediaList] = useState([]);

  useEffect(() => {
    const media = [
      {
        icon: <FontAwesomeIcon icon={faInstagram} />,
        link: contacts.Instagram,
      },
      {
        icon: <FontAwesomeIcon icon={faFacebook} />,
        link: contacts.Facebook,
      },
      {
        icon: <FontAwesomeIcon icon={faYoutube} />,
        link: contacts.YouTube,
      },
      {
        icon: <FontAwesomeIcon icon={faLinkedin} />,
        link: contacts.LinkedIn,
      },
    ];

    const filteredMediaList = media.filter((item) => item.link !== "");
    setMediaList(filteredMediaList);
  }, [contacts]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Router>
          <Header globalInfo={globalInfo} />
          <Navbar
            media={mediaList}
            setIsActiveSearchBar={setIsActiveSearchBar}
            isActiveSearchBar={isActiveSearchBar}
            globalInfo={globalInfo}
          />
          <MobileNav />
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route
                index
                element={<Home media={mediaList} globalInfo={globalInfo} />}
              />

              <Route
                path="/products"
                element={<ProductsPage media={mediaList} />}
              />

              <Route
                path="/products/category/:category"
                element={<ProductsPage media={mediaList} />}
              />

              <Route path="/portfolio" element={<PortfolioPage />} />

              <Route
                path="/portfolio/category/:category"
                element={<PortfolioPage />}
              />

              <Route
                path="/contact"
                element={
                  <ContactPage media={mediaList} globalInfo={globalInfo} />
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
          <Footer media={mediaList} globalInfo={globalInfo} />

          <MobileTabs />
        </Router>
      )}
    </>
  );
};

export default App;
