import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import fr from "../assets/images/navbar/fr.jpg";
import en from "../assets/images/navbar/en.jpg";
import ar from "../assets/images/navbar/ar.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faGear } from "@fortawesome/free-solid-svg-icons";

import sounds1 from "../assets/sounds/click-notification.mp3";
import sounds2 from "../assets/sounds/click-button.mp3";
import LazyLoadImageWrapper from "./LazyLoadImageWrapper";
import { LANGUAGES } from "../i18n";
import { useTranslation } from "react-i18next";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Navbar = ({ media, isActiveSearchBar, globalInfo }) => {
  const { i18n, t } = useTranslation();

  const pages = ["Home", "Portfolio", "Products", "Contact"];

  const [isLangActive, setisLangActive] = useState(false);
  const handleSettings = (event) => {
    event.stopPropagation();

    const clickSound = new Audio(sounds2);
    clickSound.play();
    setisLangActive(!isLangActive);
  };

  const [isActiveNav, setIsActiveNav] = useState(false);
  useEffect(() => {
    const handleNavbar = () => {
      let x = window.scrollY;
      if (x >= 120) {
        setIsActiveNav(true);
      } else {
        setIsActiveNav(false);
      }
    };

    handleNavbar();

    document.addEventListener("scroll", handleNavbar);

    return () => {
      document.removeEventListener("scroll", handleNavbar);
    };
  }, []);

  const [isActiveMenuNav, setIsActiveMenuNav] = useState(false);

  const handleMobileNavMenu = () => {
    setIsActiveMenuNav(!isActiveMenuNav);
  };

  const handleDocumentClick = (event) => {
    const isLangMenu = event.target.closest(".menu-langs");
    const isMobileMenu = event.target.closest(".bars-icon");

    if (!isLangMenu) {
      setisLangActive(false);
    }

    if (!isMobileMenu) {
      setIsActiveMenuNav(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const [brandLogo, setBrandLogo] = useState(null);

  useEffect(() => {
    setBrandLogo(globalInfo?.logoImage);
  }, [globalInfo]);

  return (
    <nav className={`navbar ${isActiveNav ? "active" : ""}`}>
      <div className="container">
        <div className="bars-icon" onClick={handleMobileNavMenu}>
          <FontAwesomeIcon icon={faBars} />

          {/* Drop-down menu */}
          <div
            className={`drop-down-menu d-flex flex-column gap-2 ${
              isActiveMenuNav ? "active" : ""
            }`}
          >
            {/* Pages Links */}
            {pages.map((page, index) => (
              <Link
                className="text-center"
                to={page.toLowerCase() === "home" ? "/" : page.toLowerCase()}
                key={index}
              >
                {t(`navbar.${page.toLowerCase()}`)}
              </Link>
            ))}
          </div>
        </div>
        <Link to={"/"}>
          <div className="brand-logo">
            {brandLogo && (
              <LazyLoadImageWrapper
                src={IMAGE_URL + "/image/" + brandLogo}
                alt={"Sofa"}
              />
            )}
          </div>
        </Link>
        <div className="nav-items">
          {pages.map((page, index) => (
            <NavLink
              to={page.toLowerCase() === "home" ? "/" : page.toLowerCase()}
              className="nav-item"
              key={index}
              onClick={() => window.scrollTo(0, 0)}
            >
              {t(`navbar.${page.toLowerCase()}`)}
            </NavLink>
          ))}
        </div>
        <div className="buttons">
          {/* media-icons */}
          <div className="media-icons">
            {media &&
              media.map((item, index) => (
                <Link to={item.link} key={index}>
                  <div className="icon">{item.icon}</div>
                </Link>
              ))}
          </div>
          {/* Settings */}
          <div className="position-relative" onClick={handleSettings}>
            <div className="settings">
              <FontAwesomeIcon icon={faGear} color="#526c6f" />
            </div>
            <div className={`menu-langs ${isLangActive ? "active" : ""}`}>
              <p className="menu-langs-title">{t(`navbar.language`)}</p>
              {LANGUAGES &&
                LANGUAGES.map((lang, index) => (
                  <div
                    className="lan d-flex align-items-center gap-4"
                    data-lang={lang.code}
                    key={index}
                    onClick={() => i18n.changeLanguage(lang.code)}
                  >
                    <img
                      loading="lazy"
                      src={
                        lang.code === "en" ? en : lang.code === "fr" ? fr : ar
                      }
                      alt="Sofa"
                    />
                    <span>{lang.label}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
