import React, { createContext, useState, useEffect } from "react";
import { getAllProducts } from "../api/functions/products";
import { getAllCategories } from "../api/functions/categories";
import { getAllReviews } from "../api/functions/reviews";
import { getAllCompanies } from "../api/functions/company";
import { getAllSlideItems } from "../api/functions/heroSlider";
import { getAllPortfolioImages } from "../api/functions/portfolio";
import { getWebsiteInfo } from "../api/functions/websiteInfos";
import showToast from "../components/showToast";
import { getAllContacts } from "../api/functions/contacts";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [portfolioImages, setPortfolioImages] = useState([]);
  const [websiteInfo, setWebsiteInfo] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productRes = await getAllProducts();
        const categoryRes = await getAllCategories();
        const reviewRes = await getAllReviews();
        const companiesRes = await getAllCompanies();
        const slidersRes = await getAllSlideItems();
        const portfolioRes = await getAllPortfolioImages();
        const websiteInfoRes = await getWebsiteInfo();
        const contactsRes = await getAllContacts();

        if (
          productRes.status === 200 &&
          categoryRes.status === 200 &&
          reviewRes.status === 200 &&
          companiesRes.status === 200 &&
          slidersRes.status === 200 &&
          portfolioRes.status === 200 &&
          websiteInfoRes.status === 200 &&
          contactsRes.status === 200
        ) {
          setProducts(productRes.data.products);
          setCategories(categoryRes.data.categories);
          setReviews(reviewRes.data.reviews);
          setCompanies(companiesRes.data.companies);
          setSliders(slidersRes.data.sliders);
          setPortfolioImages(portfolioRes.data.images);
          setWebsiteInfo(websiteInfoRes.data.websiteInfo);
          setContacts(contactsRes.data.contacts[0]);
        }
      } catch (error) {
        showToast(
          "Error fetching data from service, please run the server!",
          "error"
        );
      }
    };

    fetchData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        products,
        setProducts,
        categories,
        setCategories,
        reviews,
        setReviews,
        companies,
        setCompanies,
        sliders,
        setSliders,
        portfolioImages,
        setPortfolioImages,
        websiteInfo,
        setWebsiteInfo,
        contacts,
        setContacts,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
