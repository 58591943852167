import React from "react";
import { Link } from "react-router-dom"; // If you're using React Router
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBox,
  faEnvelope,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

const pages = [
  { title: "Home", icon: faHome },
  { title: "products", icon: faBox },
  { title: "Portfolio", icon: faImage },
  { title: "Contact", icon: faEnvelope },
];

const MobileTabs = () => {
  return (
    <nav className="mobile-tabs navbar fixed-bottom navbar-light bg-light">
      <ul className="navbar-nav w-100">
        {pages.map((page, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${page.title.toLowerCase()}`}
              className="d-flex flex-column text-center gap-2"
            >
              <FontAwesomeIcon
                icon={page.icon}
                className="mt-2 me-2"
                fontSize={20}
              />
              {page.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MobileTabs;
