import React, { useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import LazyLoadImageWrapper from "./LazyLoadImageWrapper";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { getAllCategories } from "../api/functions/categories";
import Downloads from "./downloads";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const CategorySlider = ({ categories }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = (name) => {
    navigate(`/products/category/${name}`);
    window.scrollTo(0, 0);
  };

  return (
    <Swiper
      spaceBetween={15}
      slidesPerView={4}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      dir={i18n.language === "ar" ? "ltr" : "ltr"}
      modules={[Autoplay]}
      breakpoints={{
        // when window width is <= (small devices like mobile phones)
        2000: {
          slidesPerView: 5,
        },
        1200: {
          slidesPerView: 4,
        },
        800: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 2,
        },
      }}
    >
      {categories?.length > 0 ? (
        categories?.map((cate) => (
          <SwiperSlide key={cate.id}>
            <div
              className="cate-card"
              onClick={() => handleNavigate(cate?.name.en)}
            >
              <figure>
                <LazyLoadImageWrapper
                  src={`${IMAGE_URL}/image/${cate?.image}`}
                  alt={"Sofa"}
                />
              </figure>
              <div className="content">
                <span className="text-center card-title">
                  {i18n.language === "en"
                    ? cate?.name.en
                    : i18n.language === "fr"
                    ? cate?.name.fr
                    : cate?.name.ar}
                </span>
              </div>
            </div>
          </SwiperSlide>
        ))
      ) : (
        <Downloads />
      )}
    </Swiper>
  );
};

export default CategorySlider;
