import React from "react";

const Downloads = () => {
  return (
    <div className="downloads">
      <div className="blue-line"></div>
      <div className="blue-line-two"></div>
    </div>
  );
};

export default Downloads;
