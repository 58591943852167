import React from "react";
import { useTranslation } from "react-i18next";

const Breadcrumb = ({ page }) => {
  const { t, i18n } = useTranslation();

  return (
    <div id="breadcrumb">
      <div className="container">
        {i18n.language === "en" || i18n.language === "fr" ? (
          <p>Home / {page}</p>
        ) : (
          <p>
            {t(`navbar.home`)} / {t(`navbar.${page}`)}
          </p>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
