import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CategorySlider from "../components/CategorySlider";
import Reviews from "../components/Reviews";
import ContactForm from "../components/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Clients from "../components/Clients";
import { useTranslation } from "react-i18next";
import ProductCard from "../components/ProductCard";
import { Animated } from "react-animated-css";
import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import { AppContext } from "../context/AppContext";
import { getAllProducts } from "../api/functions/products";
import { getAllSlideItems } from "../api/functions/heroSlider";
import Downloads from "../components/downloads";
import Portfolio from "../components/Portfolio";
import { getAllPortfolioImages } from "../api/functions/portfolio";
import { getAllCategories } from "../api/functions/categories";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Home = ({ media, globalInfo }) => {
  const {
    products,
    setProducts: setProductsContext,
    categories,
    setCategories: setCategoriesContext,
    sliders,
    setSliders: setSlidersContext,
    portfolioImages,
    setPortfolioImages: setPortfolioImagesContext,
  } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate("/products");
    window.scrollTo(0, 0);
  };

  // const fetchData = async () => {
  //   const productsRes = await getAllProducts();
  //   if (productsRes.status === 200) {
  //     setProductsContext(productsRes.data.products);
  //   }

  //   const categoriesRes = await getAllCategories();
  //   if (categoriesRes.status === 200) {
  //     setCategoriesContext(categoriesRes.data.categories);
  //   }

  //   const slidersRes = await getAllSlideItems();
  //   if (slidersRes.status === 200) {
  //     setSlidersContext(slidersRes.data.sliders);
  //   }

  //   const portfolioRes = await getAllPortfolioImages();
  //   if (portfolioRes.status === 200) {
  //     setPortfolioImagesContext(portfolioRes.data.images);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <>
      <div id="hero" dir="ltr">
        <HeroSlider
          slidingDuration={500}
          slidingDelay={0}
          height="85vh"
          autoplay
        >
          {sliders?.length > 0 ? (
            sliders?.map((slide) => (
              <Slide
                key={slide.id}
                shouldRenderMask
                label={
                  i18n.language === "en"
                    ? slide?.title.en
                    : i18n.language === "fr"
                    ? slide?.title.fr
                    : slide?.title.ar
                }
                background={{
                  backgroundImageSrc: `${IMAGE_URL}/image/${slide?.image}`,
                }}
              >
                <div className="overlay-bg"></div>
                <Overlay>
                  <div className="hero-content">
                    <Animated
                      animationIn="fadeIn"
                      animationOut="fadeOut"
                      isVisible={true}
                    >
                      <h1 className="text-white mb-4">
                        {i18n.language === "en"
                          ? slide?.title.en
                          : i18n.language === "fr"
                          ? slide?.title.fr
                          : slide?.title.ar}
                      </h1>
                    </Animated>
                    <Animated
                      animationIn="fadeIn"
                      animationOut="fadeOut"
                      isVisible={true}
                    >
                      <p className="text-white mb-4">
                        {i18n.language === "en"
                          ? slide.subtitle.en
                          : i18n.language === "fr"
                          ? slide.subtitle.fr
                          : slide.subtitle.ar}
                      </p>
                    </Animated>
                    <button className="button colored">
                      {i18n.language === "en"
                        ? slide.button.en
                        : i18n.language === "fr"
                        ? slide.button.fr
                        : slide.button.ar}
                    </button>
                  </div>
                </Overlay>
              </Slide>
            ))
          ) : (
            <Downloads />
          )}
          <MenuNav />
        </HeroSlider>
      </div>

      <div id="categories" className="default-padding">
        <div className="container">
          <div className="pb-50">
            <h2 className="title">{t(`categories.section_title`)}</h2>
            <p>{t(`categories.section_desc`)}</p>
          </div>
          <main className="pt-3 mt-3">
            <CategorySlider categories={categories} />
          </main>
        </div>
      </div>

      <div id="portfolio" className="default-padding">
        <div className="container">
          <div className="col-md-12 col-sm-12 pb-50">
            <h2 className="heading">{t("portfolio.section_title")}</h2>
            <p>{t(`portfolio.section_desc`)}</p>
          </div>
          {portfolioImages.length > 0 ? (
            <>
              <div className="mb-4">
                <Portfolio portfolioImages={portfolioImages.slice(0, 15)} />
              </div>
              <button className="button" onClick={() => navigate("/portfolio")}>
                {t(`portfolio.button`)}
              </button>
            </>
          ) : (
            <Downloads />
          )}
        </div>
      </div>

      <div id="products" className="default-padding">
        <div className="container">
          <div className="pb-50">
            <h2 className="title">{t(`products.section_title`)}</h2>
            <p>{t(`products.section_desc`)}</p>
          </div>
          <div className="row pt-3">
            {products.length > 0 ? (
              products
                .slice(0, 8)
                .map((product) => (
                  <ProductCard key={product.id} product={product} />
                ))
            ) : (
              <Downloads />
            )}
          </div>
          {products.length > 5 && (
            <button className="button" onClick={handleViewMoreClick}>
              {t(`products.button`)}
            </button>
          )}
        </div>
      </div>

      <section id="reviews" className="default-padding my-5">
        <div className="background-overlay"></div>
        <div className="background-image">
          <div className="container">
            <main dir="ltr">
              <Reviews />
            </main>
          </div>
        </div>
      </section>

      <div id="clients" className="default-padding default-margin">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 pb-50">
              <h2 className="title">{t(`clients.section_title`)}</h2>
              <p className="desc text-gray">{t(`clients.section_desc`)}</p>
            </div>
            <div className="my-3 col-md-12 col-sm-12">
              <Clients />
            </div>
          </div>
        </div>
      </div>

      <section id="contact" className="default-padding-top">
        <div className="container">
          <h2 className="title text-center">{t(`contact.section_title`)}</h2>
          <p className="text-center mb-3 mb-3">{t(`contact.section_desc`)}</p>
          <main className="row pt-5">
            <div className="contact-information col-lg-4 col-md-12 col-sm-12 mb-5">
              <div className="mb-3">
                <Link to="#" className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon icon={faPhone} />{" "}
                  <span className="ms-2">+212 6 00 00 00 00</span>
                </Link>
              </div>
              <div className="mb-3">
                <a
                  href="example@domain.com"
                  className="d-flex gap-2 align-items-center"
                >
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  <div className="ms-2">example@domain.com</div>
                </a>
              </div>
              <div className="mb-3">
                <a href="#" className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span className="ms-2">{t(`company-infos.adress`)}</span>
                </a>
              </div>
              <div className="buttons">
                <div className="media-icons">
                  {media &&
                    media.map((item, index) => (
                      <Link to={item.link} key={index}>
                        <div className="icon">{item.icon}</div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div className="contact-form col-lg-8 col-md-12 col-sm-12">
              <ContactForm />
            </div>
          </main>
        </div>
        {globalInfo.mapUrl ? (
          <div className="contact-map">
            <iframe
              src={globalInfo.mapUrl}
              width="100%"
              height="600px"
              loading="lazy"
            ></iframe>
          </div>
        ) : (
          <div></div>
        )}
      </section>
    </>
  );
};

export default Home;
