import React, { useContext, useEffect } from "react";
import Portfolio from "../components/Portfolio";
import Breadcrumb from "../components/Breadcrumb";
import Banner from "../components/Banner";
import BannerIMAGE from "../assets/images/banners/home-interior.png";

import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { getAllPortfolioImages } from "../api/functions/portfolio";
import Downloads from "../components/downloads";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const PortfolioPage = () => {
  const { t } = useTranslation();

  const { portfolioImages, setPortfolioImages: setPortfolioImagesContext } =
    useContext(AppContext);

  // const fetchData = async () => {
  //   const portfolioRes = await getAllPortfolioImages();

  //   if (portfolioRes.status === 200) {
  //     setPortfolioImagesContext(portfolioRes.data.images);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <>
      <Banner page="portfolio" imageUrl={BannerIMAGE} />
      <Breadcrumb page="portfolio" />
      <section id="portfolio" className="default-padding">
        <div className="container">
          <h2 className="title">{t(`portfolio.section_title`)}</h2>
          <p>{t(`portfolio.section_desc`)}</p>

          <main className="mb-3" style={{ overflow: "hidden" }}>
            {portfolioImages.length > 0 ? (
              <Portfolio portfolioImages={portfolioImages} />
            ) : (
              <Downloads />
            )}
          </main>
        </div>
      </section>
    </>
  );
};

export default PortfolioPage;
