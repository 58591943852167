import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelopeOpen,
  faLocation,
} from "@fortawesome/free-solid-svg-icons";
import { getAllCategories } from "../api/functions/categories";
import Downloads from "../components/downloads";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Footer = ({ media, globalInfo }) => {
  const { categories } = useContext(AppContext);

  const { t, i18n } = useTranslation();
  const [currentYear, setYear] = useState();
  const [footerLogo, setFooterLogo] = useState(null);
  const [description, setDescription] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [adress, setAdress] = useState({});
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (globalInfo) {
      setFooterLogo(globalInfo.logoImage);
      setDescription(globalInfo.description);
      setPhoneNumber(globalInfo.phone);
      setAdress(globalInfo.address);
      setEmail(globalInfo.mail);
    }
  }, [globalInfo]);

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  const navigate = useNavigate();

  const handleNavigate = (tag) => {
    navigate(`/products/category/${tag}`);
    window.location.reload();
  };

  const pages = ["Home", "Portfolio", "Products", "Contact"];

  return (
    <>
      <footer className="footer-section">
        <div className="footer-cta pt-5 pb-5">
          <div className="container">
            <div className="row gap-3">
              <div className="col-lg-4 mx-auto mb-4">
                {adress?.en && (
                  <div className="single-cta">
                    <div className="icon">
                      <FontAwesomeIcon icon={faLocation} />
                    </div>
                    <div className="cta-text">
                      <h4>{t(`footer.find`)}</h4>
                      <span>
                        {i18n.language === "en"
                          ? adress?.en
                          : i18n.language === "ar"
                          ? adress?.ar
                          : adress?.fr}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-3 mx-auto mb-4">
                {phoneNumber && (
                  <div className="single-cta">
                    <div className="icon">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div className="cta-text">
                      <h4>{t(`footer.call`)}</h4>
                      <span>+{phoneNumber}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-4 mx-auto">
                {email && (
                  <div className="single-cta">
                    <div className="icon">
                      <FontAwesomeIcon icon={faEnvelopeOpen} />
                    </div>
                    <div className="cta-text">
                      <h4>{t(`footer.mail`)}</h4>
                      <span>{email}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to="/">
                      <img
                        src={`${IMAGE_URL}/image/${footerLogo}`}
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                      {i18n.language === "en"
                        ? description?.en
                        : i18n.language === "ar"
                        ? description?.ar
                        : description?.fr}
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <div className="footer-social-links">
                      {media &&
                        media.map((item, index) => (
                          <Link to={item.link} key={index}>
                            <div className="icon">{item.icon}</div>
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    {pages.map((page, index) => (
                      <li key={index}>
                        <Link
                          to={
                            page.toLowerCase() === "home"
                              ? "/"
                              : page.toLowerCase()
                          }
                          key={index}
                        >
                          {t(`navbar.${page.toLowerCase()}`)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Tags</h3>
                  </div>
                  {categories.length > 0 ? (
                    categories.map((tag, index) => (
                      <button
                        onClick={() => handleNavigate(tag?.name?.en)}
                        className="mb-1 tag"
                        key={index}
                      >
                        {tag?.name?.en}
                      </button>
                    ))
                  ) : (
                    <Downloads />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright &copy; {currentYear}, All Right Reserved
                    <Link to={"/"} className="ps-2">
                      BIGWALL TECHNOLOGY
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
