import React from "react";
import defaultIMAGE from "../assets/images/banners/home-interior.png";
import { useTranslation } from "react-i18next";

const Banner = ({ artist, page, imageUrl }) => {
  const { t } = useTranslation();

  return (
    <div
      id="banner"
      className="ep-workshop_header"
      style={{
        backgroundImage: `url(${imageUrl || defaultIMAGE})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        minHeight: "30vh",
      }}
    >
      <div className="is-overlay"></div>
      <div className="about-header_deatils">
        <div className="text-center">
          <h1 className="text-white title">{t(`navbar.${page}`)}</h1>
          {artist && (
            <h3 className="text-white">
              <span className="text-capitalize">{artist}</span>
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banner;
