import React, { useContext, useEffect } from "react";
import { faQuoteRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { getAllReviews } from "../api/functions/reviews";
import Downloads from "./downloads";
import showToast from "./showToast";

const Reviews = () => {
  const { i18n } = useTranslation();
  const { reviews, setReviews: setReviewsContext } = useContext(AppContext);

  // const fetchData = async () => {
  //   const reviewRes = await getAllReviews();
  //   if (reviewRes.status === 200) {
  //     setReviewsContext(reviewRes.data.reviews);
  //   } else {
  //     showToast("Error fetching data from service", "error");
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <>
      {reviews ? (
        <Swiper
          spaceBetween={50}
          centeredSlides={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <div className="reviews">
                <div className="quoteIcon mx-auto my-3">
                  <FontAwesomeIcon icon={faQuoteRight} />
                </div>
                <h5 className="mx-auto mt-3 mb-4 text-white">
                  {review?.clientName}
                </h5>
                <p className="text-center w-80 m-auto text-white">
                  {i18n.language === "en"
                    ? review?.description.en
                    : i18n.language === "fr"
                    ? review?.description.fr
                    : review?.description.ar}
                </p>
                <div
                  id="stars"
                  className="stars d-flex aliogn-items-center justify-content-center gap-2 pt-4"
                >
                  {[...Array(review.stars)].map((_, starIndex) => (
                    <FontAwesomeIcon
                      key={starIndex}
                      icon={faStar}
                      color="#e5ac00"
                    />
                  ))}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Downloads />
      )}
    </>
  );
};

export default Reviews;
