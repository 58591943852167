import React, { useEffect } from "react";
import fjGallery from "flickr-justified-gallery";
import Downloads from "./downloads";
import Gallery from "react-photo-album";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Portfolio = ({ portfolioImages }) => {
  useEffect(() => {
    if (portfolioImages?.length > 0) {
      setTimeout(() => {
        fjGallery(document.querySelectorAll(".fj-gallery"), {
          itemSelector: ".fj-gallery-item",
        });
      }, 100);
    }
  }, [portfolioImages]);

  const convertImagesForGallery = () => {
    return portfolioImages.map((image) => ({
      src: `${IMAGE_URL}/image/${image?.image}`,
      width: 4,
      height: 3,
    }));
  };

  return (
    <>
      <div className="fj-gallery-item">
        <div className="fj-gallery">
          {portfolioImages
            ?.slice(0, 1)
            .flatMap((image, index) =>
              image ? (
                <Gallery
                  key={index}
                  layout="rows"
                  photos={convertImagesForGallery()}
                />
              ) : (
                <Downloads />
              )
            )}
        </div>
      </div>
    </>
  );
};

export default Portfolio;
