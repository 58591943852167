import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { sendEmail } from "../api/functions/sendEmail";
import showToast from "./showToast";

const ContactForm = () => {
  const { t } = useTranslation();
  const [alertMessage, setAlertMessage] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("contact.formValidation.name")),
      email: Yup.string()
        .email(t("contact.formValidation.emailInvalid"))
        .required(t("contact.formValidation.email")),
      phone: Yup.string()
        .matches(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
          t("contact.formValidation.phoneInvalid")
        )
        .required(t("contact.formValidation.phone")),
      subject: Yup.string().required(t("contact.formValidation.subject")),
      message: Yup.string().required(t("contact.formValidation.message")),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        if (values) {
          setTimeout(() => {
            showToast(t(`contact.successMessage`), "success");
          }, 1000);
        }

        resetForm();
      } catch (error) {
        // console.log();
      } finally {
        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
      }
    },
  });

  return (
    <>
      {alertMessage && (
        <div className="alert alert-success" role="alert">
          {alertMessage}
        </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6 mb-3">
            <div className="md-form mb-0">
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder={t(`contact.placeholders.name`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
            </div>
            <span className="text-danger" id="nameError">
              {formik.touched.name && formik.errors.name}
            </span>
          </div>

          <div className="col-md-6">
            <div className="md-form mb-0">
              <input
                type="text"
                id="email"
                name="email"
                className="form-control"
                placeholder={t(`contact.placeholders.email`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>
            <span className="text-danger" id="emailError">
              {formik.touched.email && formik.errors.email}
            </span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 mb-3">
            <div className="md-form mb-0">
              <input
                type="text"
                id="phone"
                name="phone"
                className="form-control"
                placeholder={t(`contact.placeholders.phone`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
            </div>
            <span className="text-danger" id="phoneError">
              {formik.touched.phone && formik.errors.phone}
            </span>
          </div>

          <div className="col-md-6 mb-3">
            <div className="md-form mb-0">
              <input
                type="text"
                id="subject"
                name="subject"
                className="form-control"
                placeholder={t(`contact.placeholders.subject`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subject}
              />
            </div>
            <span className="text-danger" id="subjectError">
              {formik.touched.subject && formik.errors.subject}
            </span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="md-form">
              <textarea
                type="text"
                id="message"
                name="message"
                rows="5"
                className="form-control md-textarea"
                placeholder={t(`contact.placeholders.message`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              ></textarea>
            </div>
            <span className="text-danger" id="messageError">
              {formik.touched.message && formik.errors.message}
            </span>
          </div>
        </div>

        <button
          className="btn btn-custom"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>
            {formik.isSubmitting
              ? t(`contact.button.sendding`)
              : t(`contact.button.send`)}
          </span>
        </button>
      </form>
    </>
  );
};

export default ContactForm;
