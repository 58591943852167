import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const toHomePage = () => {
    navigate("/");
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate("/");
  //   }, 1000);
  // });

  return (
    <div id="notfound" className="default-padding">
      <div className="container">
        <span>{t(`page-not-found.status`)}</span>
        <h2 className="text-center">
          <span>{t(`page-not-found.content.oops`)}</span>{" "}
          {t(`page-not-found.content.where`)} <br />
          {t(`page-not-found.content.are-we`)}
        </h2>
        <button className="button" onClick={toHomePage}>
          {t(`page-not-found.button`)}
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
