import React, { useEffect, useContext } from "react";

// SWIPER INIT
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import LazyLoadImageWrapper from "./LazyLoadImageWrapper";
import { AppContext } from "../context/AppContext";
import { bufferToDataURL } from "../utils/bufferToDataURL";
import { getAllCompanies } from "../api/functions/company";
import Downloads from "./downloads";
import showToast from "./showToast";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Clients = () => {
  const { companies, setCompanies: setCompaniesContext } =
    useContext(AppContext);

  // const fetchData = async () => {
  //   const companiesRes = await getAllCompanies();
  //   setCompaniesContext(companiesRes?.data?.companies);
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <>
      <Swiper
        spaceBetween={30}
        slidesPerView={5}
        centeredSlides={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {companies.length > 0 ? (
          companies.map((ct, index) => (
            <SwiperSlide key={index}>
              <LazyLoadImageWrapper
                src={`${IMAGE_URL}/image/${ct.image}`}
                alt={"Sofa"}
              />
            </SwiperSlide>
          ))
        ) : (
          <Downloads />
        )}
      </Swiper>
    </>
  );
};

export default Clients;
