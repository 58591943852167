import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faPhoneFlip,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import Banner from "../components/Banner";
import BannerIMAGE from "../assets/images/banners/location.jpg";
import { useTranslation } from "react-i18next";

const ContactPage = ({ media, globalInfo }) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <Banner page="contact" imageUrl={BannerIMAGE} />
      <Breadcrumb page="contact" />
      <section id="contact" className="default-padding-top">
        <div className="container">
          <h2 className="title text-center">{t(`contact.section_title`)}</h2>
          <p className="text-center mb-3 mb-3">{t(`contact.section_desc`)}</p>
          <main className="row pt-5">
            <div className="contact-information col-lg-4 col-md-12 col-sm-12 mb-5">
              <div className="mb-3">
                <Link to="#" className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon={i18n.language === "ar" ? faPhoneFlip : faPhone}
                  />
                  <span className="ms-2">+{globalInfo.phone}</span>
                </Link>
              </div>
              <div className="mb-3">
                <Link to="#" className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <div className="ms-2">{globalInfo.mail}</div>
                </Link>
              </div>
              <div className="mb-3">
                <Link to="#" className="d-flex gap-2 align-items-center">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span className="ms-2">
                    {i18n.language === "en"
                      ? globalInfo.address?.en
                      : i18n.language === "ar"
                      ? globalInfo.address?.ar
                      : globalInfo.address?.fr}
                  </span>
                </Link>
              </div>
              <div className="buttons">
                <div className="media-icons">
                  {media &&
                    media.map((item, index) => (
                      <Link to={item.link} key={index}>
                        <div className="icon">{item.icon}</div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div className="contact-form col-lg-8 col-md-12 col-sm-12">
              <ContactForm />
            </div>
          </main>
        </div>
        {globalInfo.mapUrl ? (
          <div className="contact-map">
            <iframe
              src={globalInfo.mapUrl}
              width="100%"
              height="600px"
              loading="lazy"
            ></iframe>
          </div>
        ) : (
          <div></div>
        )}
      </section>
    </>
  );
};

export default ContactPage;
